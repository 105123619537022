import { Tag } from './styles'

const CustomModuleStatusTag = ({ status }) => {
  return (
    <Tag className={`status-tag ${status}`}>
      <span className="indicator" /> {status}
    </Tag>
  )
}

export default CustomModuleStatusTag
