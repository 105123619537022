import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton, Select, Input, Slider, Switch, Progress, Tabs } from 'antd'
import {
  CheckmarkCircle24Filled,
  Add24Regular,
  Rocket24Regular,
  DeveloperBoard24Regular,
  Storage24Regular,
  Battery224Regular,
  Battery524Regular,
  Battery824Regular,
  Battery024Filled,
  Wifi124Regular,
  HatGraduation24Regular,
} from '@fluentui/react-icons'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import { isDataWarsHostName } from '@/helpers/env'
import { capitalize } from '@/utils/functions'
import { showToast } from '@/utils/toast'
import HTMLBlock from '@/components/HTMLBlock'
import Button from '@/components/Button'
import ModuleImageSeach from './components/ModuleImageSearch'
import { createCustomModule, updateCustomModuleWizard, resetCustomModuleWizard } from '@/store/modules/actions'
import { fetchStacks, fetchStackSizes } from '@/store/stacks/actions'
import NvidiaIcon from '@/assets/images/icons/nvidia.png'
import { Modal } from './styles'
import ProLabel from '@/components/ProLabel'

const CustomModuleModal = () => {
  const dispatch = useDispatch()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { customModuleWizard, isModuleLoading } = useSelector((state) => state.modules)
  const { stacks, stackSizes } = useSelector((state) => state.stacks)

  const handleUpdateCustomModuleWizard = (field, value) => {
    dispatch(updateCustomModuleWizard({ [field]: value }))
  }

  const handleCloseModal = () => {
    dispatch(resetCustomModuleWizard())
  }

  const handleCustomModuleCreate = () => {
    const auxCustomModule = {
      name: customModuleWizard?.name,
      description: customModuleWizard?.description,
      svg_icon_url: customModuleWizard?.svgIconUrl,
      stack_id: customModuleWizard?.stack?.id,
      stack_size: customModuleWizard?.stackSize?.name,
      ...(customModuleWizard?.gpuType && {
        gpu_type: customModuleWizard?.gpuType,
        gpu_count: customModuleWizard?.gpuCount,
      }),
      internet_edition: !!customModuleWizard?.internetEdition,
      internet_resolution: !!customModuleWizard?.internetResolution,
    }

    dispatch(
      createCustomModule(
        auxCustomModule,
        {
          account_id: currentAccount?.id,
        },
        handleCloseModal,
        (message) => showToast(message, 'error'),
      ),
    )
  }

  useEffect(() => {
    if (!customModuleWizard?.stack && stacks?.length) {
      handleUpdateCustomModuleWizard('stack', stacks?.[0])
    }
  }, [stacks])

  useEffect(() => {
    if (!customModuleWizard?.stackSize && stackSizes?.length) {
      handleUpdateCustomModuleWizard('stackSize', stackSizes?.[0])
    }
  }, [stackSizes])

  useEffect(() => {
    if (customModuleWizard?.isModalOpen) {
      dispatch(fetchStacks({ is_custom_module_available: true, status: 'published', page_size: 'none' }))
      dispatch(fetchStackSizes())
    }
  }, [customModuleWizard?.isModalOpen])

  return (
    <Modal
      className="custom-module-modal"
      open={!!customModuleWizard?.isModalOpen}
      onCancel={handleCloseModal}
      keyboard={false}
      maskClosable={false}
      footer={null}
      destroyOnClose
    >
      <div className="container">
        <div className="content-card">
          <div className="hero-container">
            <div className="icon-container">
              <Add24Regular className="icon" />
            </div>

            <h4 className="title">New custom project</h4>
          </div>

          <div className="content">
            <div className="title-container">
              <p className="text">Select a project image</p>
              <ModuleImageSeach
                label="Search project image"
                onSelect={(imageUrl) => handleUpdateCustomModuleWizard('svgIconUrl', imageUrl)}
              />

              <Input
                size="large"
                placeholder="Project title"
                value={customModuleWizard?.name}
                onChange={(e) => handleUpdateCustomModuleWizard('name', capitalize(e.target.value))}
              />

              <Input.TextArea
                size="large"
                rows={4}
                placeholder="Add a description..."
                value={customModuleWizard?.description}
                onChange={(e) => handleUpdateCustomModuleWizard('description', e.target.value)}
              />
            </div>

            <div className="stacks-container">
              <div className="section">
                <p className="section-name">Stacks</p>
                <p className="text">Choose your stack</p>
              </div>

              <div className="stacks-content">
                {stacks?.map((stack) => (
                  <div
                    key={stack?.id}
                    className="stack-content"
                    onClick={() => handleUpdateCustomModuleWizard('stack', stack)}
                  >
                    <div className={`stack-box ${customModuleWizard?.stack?.id === stack?.id ? 'selected' : ''}`}>
                      <div className="image-container">
                        <img className="image" src={stack?.image_url} alt={stack?.name} />
                      </div>

                      <h5 className="name">{stack?.name}</h5>

                      <HTMLBlock content={stack?.description_html} className="description" />
                    </div>
                  </div>
                ))}

                <div className="stack-content">
                  <div className="stack-box custom">
                    <h5 className="name">Custom stack</h5>

                    <p className="description">Request your favorite environment.</p>

                    <a
                      className="link"
                      href={`mailto:support@${isDataWarsHostName ? 'datawars.io' : 'namespace.im'}?subject=Info about custom stacks on custom projects`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button ispro>Contact sales</Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="stack-sizes-container">
              <div className="section">
                <p className="section-name">Box Size</p>
                <p className="text">Choose your box size</p>
              </div>

              <div className="stack-sizes-content">
                {stackSizes?.map((stackSize) => (
                  <div
                    key={stackSize?.name}
                    className="stack-size-content"
                    onClick={() => handleUpdateCustomModuleWizard('stackSize', stackSize)}
                  >
                    <div
                      className={`stack-size-box ${customModuleWizard?.stackSize?.name === stackSize?.name ? 'selected' : ''}`}
                    >
                      <div className="header">
                        <div className="image-container">
                          {stackSize?.name === 'small' && <Battery224Regular />}
                          {stackSize?.name === 'medium' && <Battery524Regular />}
                          {stackSize?.name === 'large' && <Battery824Regular />}
                        </div>

                        <h5 className="name">{capitalize(stackSize?.name)}</h5>
                      </div>

                      <div className="value-boxes-container">
                        <div className="value-box">
                          <p className="key">
                            <Rocket24Regular className="icon" />
                            RAM
                          </p>

                          <p className="value">{stackSize?.ram?.human}</p>
                        </div>

                        <div className="value-box">
                          <p className="key">
                            <DeveloperBoard24Regular className="icon" />
                            CPU
                          </p>

                          <p className="value">{stackSize?.cpu?.human}</p>
                        </div>

                        <div className="value-box">
                          <p className="key">
                            <Storage24Regular className="icon" />
                            Storage
                          </p>

                          <p className="value">{stackSize?.storage?.human}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="stack-size-content">
                  <div className="stack-size-box custom">
                    <div className="header">
                      <div className="image-container">
                        <Battery024Filled />
                      </div>

                      <h5 className="name">Custom</h5>
                    </div>

                    <div className="value-boxes-container">
                      <div className="value-box">
                        <p className="text">
                          Need more powerful machines?{' '}
                          <a
                            className="link"
                            href={`mailto:support@${isDataWarsHostName ? 'datawars.io' : 'namespace.im'}?subject=Info about custom box size on custom projects`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            contact sales
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="gpus-container">
              <div className="section">
                <div className="section-content">
                  <p className="section-name">GPU</p>
                  <p className="text">Choose your GPU options</p>
                </div>

                <div className="extra-content">
                  <DeveloperBoard24Regular className="icon" />

                  <p className="text">
                    Need GPU power?{' '}
                    <a
                      className="link"
                      href={`mailto:support@${isDataWarsHostName ? 'datawars.io' : 'namespace.im'}?subject=Info about bigger GPU on custom projects`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      contact sales
                    </a>
                  </p>
                </div>
              </div>

              <div className="gpus-content">
                <div className="gpu-content">
                  <div
                    className={`gpu-box ${!customModuleWizard?.gpuType ? 'selected' : ''}`}
                    onClick={() => handleUpdateCustomModuleWizard('gpuType', null)}
                  >
                    <h5 className="name">No GPU</h5>
                  </div>

                  <div
                    className={`gpu-box ${customModuleWizard?.gpuType === 'NVIDIA T4' ? 'selected' : ''} disabled`}
                    // onClick={() => handleUpdateCustomModuleWizard('gpuType', 'NVIDIA T4')}
                  >
                    <img className="image" src={NvidiaIcon} alt="Nvidia" />
                    <h5 className="name">NVIDIA T4</h5>
                  </div>

                  <div
                    className={`gpu-box ${customModuleWizard?.gpuType === 'NVIDIA H200' ? 'selected' : ''} disabled`}
                    // onClick={() => handleUpdateCustomModuleWizard('gpuType', 'NVIDIA H200')}
                  >
                    <img className="image" src={NvidiaIcon} alt="Nvidia" />
                    <h5 className="name">NVIDIA H200</h5>
                  </div>

                  <div
                    className={`gpu-box ${customModuleWizard?.gpuType === 'NVIDIA A100' ? 'selected' : ''} disabled`}
                    // onClick={() => handleUpdateCustomModuleWizard('gpuType', 'NVIDIA A100')}
                  >
                    <img className="image" src={NvidiaIcon} alt="Nvidia" />
                    <h5 className="name">NVIDIA A100</h5>
                  </div>
                </div>

                <div className="size-content">
                  <Slider
                    marks={{
                      0: '0',
                      1: '1',
                      2: '2',
                      3: '3',
                    }}
                    value={customModuleWizard?.gpuCount}
                    max={3}
                    onChange={(value) => handleUpdateCustomModuleWizard('gpuCount', value)}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="other-configs-container">
              <div className="section">
                <p className="section-name">Internet access</p>
                <p className="text">Choose your internet options</p>
              </div>

              <div className="configs-content">
                <div className="config-content">
                  <div className="config-box">
                    <div className="header">
                      <div className="title-container">
                        <Wifi124Regular className="icon" />
                        <h5 className="title">Internet in Edition</h5>
                        <ProLabel />
                      </div>

                      <Switch
                        checked={customModuleWizard?.internetEdition}
                        onChange={(checked) => handleUpdateCustomModuleWizard('internetEdition', checked)}
                        disabled
                      />
                    </div>

                    <p className="description">Create an environment with access to the web.</p>
                  </div>

                  <div className="config-box">
                    <div className="header">
                      <div className="title-container">
                        <HatGraduation24Regular className="icon" />
                        <h5 className="title">Internet available for students</h5>
                        <ProLabel />
                      </div>

                      <Switch
                        checked={customModuleWizard?.internetResolution}
                        onChange={(checked) => handleUpdateCustomModuleWizard('internetResolution', checked)}
                        disabled
                      />
                    </div>

                    <p className="description">Allow students to use internet on this project.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="preview-card">
          <div className="content">
            <div className="header">
              <div className="image">{renderModuleImage({ svg_icon_url: customModuleWizard?.svgIconUrl })}</div>

              <div className="title-container">
                <h3 className={`title ${!customModuleWizard?.name ? 'no-content' : ''}`}>
                  {customModuleWizard?.name || 'Untitled project'}
                </h3>

                <p className="description">{customModuleWizard?.description || '—'}</p>
              </div>
            </div>

            <hr />

            <div className="section">
              <p className="section-name">Stack</p>
              <h5 className="value">{customModuleWizard?.stack?.name || '—'}</h5>
            </div>

            <hr />

            <div className="section">
              <p className="section-name">Box size</p>
              <h5 className="value">
                {customModuleWizard?.stackSize?.name ? capitalize(customModuleWizard?.stackSize?.name) : '—'}
              </h5>
            </div>

            <hr />

            <div className="section">
              <p className="section-name">GPU</p>
              <h5 className="value">
                {customModuleWizard?.gpuType && <img className="image" src={NvidiaIcon} alt="Nvidia" />}
                {customModuleWizard?.gpuType || 'No GPU'}
              </h5>
            </div>

            <hr />

            <div className="section boxes-section">
              <div className="box">
                <div className="title-container">
                  <Wifi124Regular className="icon" />
                  <p className="text">Internet in Edition</p>
                </div>

                <div className="value">
                  {customModuleWizard?.internetEdition ? (
                    <CheckmarkCircle24Filled className="check-icon" />
                  ) : (
                    <p className="text">—</p>
                  )}
                </div>
              </div>

              <div className="box">
                <div className="title-container">
                  <HatGraduation24Regular className="icon" />
                  <p className="text">Internet available for students</p>
                </div>

                <div className="value">
                  {customModuleWizard?.internetResolution ? (
                    <CheckmarkCircle24Filled className="check-icon" />
                  ) : (
                    <p className="text">—</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="actions">
            <hr />

            <div className="buttons-container">
              <Button onClick={handleCloseModal} loading={isModuleLoading}>
                Cancel
              </Button>

              <Button type="secondary" onClick={handleCustomModuleCreate} loading={isModuleLoading}>
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CustomModuleModal
