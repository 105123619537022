import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stacks: null,
  stackSizes: null,
  isStacksLoading: false,
  isStackSizesLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'stacks',
  initialState,
  reducers: {
    SET_STACKS_LOADING: (state, { payload }) => {
      state.isStacksLoading = payload
    },
    SET_STACK_SIZES_LOADING: (state, { payload }) => {
      state.isStackSizesLoading = payload
    },
    STACKS_UPDATED: (state, { payload }) => {
      state.stacks = payload
      state.error = null
    },
    STACK_SIZES_UPDATED: (state, { payload }) => {
      state.stackSizes = payload
      state.error = null
    },
    SET_STACKS_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_STACKS_STATE: () => initialState,
  },
})

export const {
  SET_STACKS_LOADING,
  SET_STACK_SIZES_LOADING,
  STACKS_UPDATED,
  STACK_SIZES_UPDATED,
  SET_STACKS_ERROR,
  RESET_STACKS_STATE,
} = slice.actions
export default slice.reducer
