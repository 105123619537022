import {
  SET_CUSTOM_MODULE_WIZARD_UPDATED,
  RESET_CUSTOM_MODULE_WIZARD_STATE,
  SET_MODULE_LOADING,
  MODULE_UPDATED,
  CUSTOM_MODULES_UPDATED,
  SET_ASSIGN_MODAL_OPEN,
  RESET_MODULES_STATE,
  SET_MODULE_ERROR,
} from '@/store/modules'
import { ATTEMPTS_ANALYTICS_UPDATED } from '@/store/accounts'
import {
  getAlgoliaModules,
  postModuleAttemptComment,
  patchModuleAttemptComment,
  deleteModuleAttemptComment,
} from '@/services/modules'
import { getCustomModules, postCustomModule } from '@/services/customModules'

const updateCustomModuleWizard = (data) => async (dispatch, getState) => {
  const { customModuleWizard } = getState().modules

  try {
    dispatch(SET_CUSTOM_MODULE_WIZARD_UPDATED({ ...customModuleWizard, ...data }))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULE_WIZARD_UPDATED({ ...customModuleWizard, error: message }))
  }
}

const resetCustomModuleWizard = () => async (dispatch, getState) => {
  const { customModuleWizard } = getState().modules

  try {
    dispatch(RESET_CUSTOM_MODULE_WIZARD_STATE())
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULE_WIZARD_UPDATED({ ...customModuleWizard, error: message }))
  }
}

const fetchCustomModules = (params) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const customModuleVersions = await getCustomModules(params)
    dispatch(CUSTOM_MODULES_UPDATED(customModuleVersions))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const createCustomModule = (customModuleData, params, onSuccess, onError) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { customModules } = getState().modules

    const newCustomModule = await postCustomModule(customModuleData, params)

    const auxCustomModules = {
      ...customModules,
      count: customModules?.count + 1,
      results: [...customModules?.results, newCustomModule],
    }

    dispatch(CUSTOM_MODULES_UPDATED(auxCustomModules))

    if (onSuccess) {
      onSuccess(newCustomModule)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))

    if (onError) {
      onError(message)
    }
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const fetchModule = (moduleId, cb) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const modules = await getAlgoliaModules(`objectID:${moduleId}`)
    dispatch(MODULE_UPDATED(modules?.[0]))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const createComment = (moduleId, moduleAttemptId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { attemptsAnalytics } = getState().accounts

    const response = await postModuleAttemptComment(moduleId, moduleAttemptId, data)
    const newCommentEvent = {
      content_md: data?.content_md,
      event_name: 'content.comment.create',
      id: response?.id,
      datetime: response?.created,
      content_html: response?.content_html,
      user: response?.user,
    }

    const auxAttemptsAnalytics = {
      ...attemptsAnalytics,
      module_attempts: attemptsAnalytics?.module_attempts.map((a) => {
        if (a.id === moduleAttemptId) {
          return {
            ...a,
            timeline: [...a.timeline, newCommentEvent],
          }
        }

        return a
      }),
    }

    dispatch(ATTEMPTS_ANALYTICS_UPDATED(auxAttemptsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const updateComment = (moduleId, moduleAttemptId, commentId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { attemptsAnalytics } = getState().accounts

    const response = await patchModuleAttemptComment(moduleId, moduleAttemptId, commentId, data)
    const newCommentEvent = {
      content_md: data?.content_md,
      event_name: 'content.comment.create',
      id: response?.id,
      datetime: response?.created,
      content_html: response?.content_html,
      user: response?.user,
    }

    const auxAttemptsAnalytics = {
      ...attemptsAnalytics,
      module_attempts: attemptsAnalytics?.module_attempts.map((a) => {
        if (a.id === moduleAttemptId) {
          return {
            ...a,
            timeline: a.timeline?.map((e) => (e.id === commentId ? newCommentEvent : e)),
          }
        }

        return a
      }),
    }

    dispatch(ATTEMPTS_ANALYTICS_UPDATED(auxAttemptsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const deleteComment = (moduleId, moduleAttemptId, commentId, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { attemptsAnalytics } = getState().accounts

    await deleteModuleAttemptComment(moduleId, moduleAttemptId, commentId)

    const auxAttemptsAnalytics = {
      ...attemptsAnalytics,
      module_attempts: attemptsAnalytics?.module_attempts.map((a) => {
        if (a.id === moduleAttemptId) {
          return {
            ...a,
            timeline: a.timeline?.filter((e) => e.id !== commentId),
          }
        }

        return a
      }),
    }

    dispatch(ATTEMPTS_ANALYTICS_UPDATED(auxAttemptsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const setAssignModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_ASSIGN_MODAL_OPEN(isOpen))
}

const resetModulesState = () => (dispatch) => {
  dispatch(RESET_MODULES_STATE())
}

export {
  updateCustomModuleWizard,
  resetCustomModuleWizard,
  fetchCustomModules,
  createCustomModule,
  fetchModule,
  createComment,
  updateComment,
  deleteComment,
  setAssignModalOpen,
  resetModulesState,
}
