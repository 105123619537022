import {
  SET_STACKS_LOADING,
  SET_STACK_SIZES_LOADING,
  STACKS_UPDATED,
  STACK_SIZES_UPDATED,
  SET_STACKS_ERROR,
  RESET_STACKS_STATE,
} from '@/store/stacks'
import { getStacks, getStackSizes } from '@/services/stacks'

const fetchStacks = (params) => async (dispatch) => {
  try {
    dispatch(SET_STACKS_LOADING(true))

    const stacks = await getStacks(params)
    dispatch(STACKS_UPDATED(stacks))
  } catch (error) {
    const { message } = error
    dispatch(SET_STACKS_ERROR(message))
  } finally {
    dispatch(SET_STACKS_LOADING(false))
  }
}

const fetchStackSizes = (params) => async (dispatch) => {
  try {
    dispatch(SET_STACK_SIZES_LOADING(true))

    const stackSizes = await getStackSizes(params)
    dispatch(STACK_SIZES_UPDATED(stackSizes))
  } catch (error) {
    const { message } = error
    dispatch(SET_STACKS_ERROR(message))
  } finally {
    dispatch(SET_STACK_SIZES_LOADING(false))
  }
}

const resetStacksState = () => (dispatch) => {
  dispatch(RESET_STACKS_STATE())
}

export { fetchStacks, fetchStackSizes, resetStacksState }
