import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CollectionsAdd24Regular } from '@fluentui/react-icons'
import { fetchCustomModules } from '@/store/modules/actions'
import { Container } from './styles'
import CustomModuleBox from '@/components/CustomModuleBox'
import { isDataWarsHostName } from '@/helpers/env'

const AutoGradedProjectsTab = ({ goToProjectsTab }) => {
  const dispatch = useDispatch()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { customModules, isModuleLoading } = useSelector((state) => state.modules)

  useEffect(() => {
    dispatch(fetchCustomModules({ account_id: currentAccount?.id }))
  }, [])

  if (isModuleLoading) {
    return <Container className="auto-graded-projects-tab">Loading...</Container>
  }

  return (
    <Container className="auto-graded-projects-tab">
      {!customModules?.count ? (
        <div className="empty-state">
          <div className="empty-state-content">
            <div className="header">
              <div className="container">
                <div className="title-container">
                  <CollectionsAdd24Regular className="icon" />

                  {isDataWarsHostName ? (
                    <h4 className="title">
                      Custom projects featuring auto-grading and automated tests are in{' '}
                      <span className="beta">Beta</span>
                    </h4>
                  ) : (
                    <h4 className="title">
                      Start creating your Custom projects featuring auto-grading and automated tests!
                    </h4>
                  )}
                </div>

                {isDataWarsHostName ? (
                  <>
                    <p className="text">
                      If you're interested in being one of the first to try it, email us at:{' '}
                      <a
                        className="link"
                        href="mailto:support@datawars.io?subject=Info about DataWars custom auto-graded projects"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@datawars.io
                      </a>
                      .
                    </p>

                    <p className="text">
                      In the meantime, all of DataWars projects support auto-grading and you can assign them today.{' '}
                      <a className="link" onClick={goToProjectsTab}>
                        See our catalog
                      </a>
                      .
                    </p>
                  </>
                ) : (
                  <p className="text">
                    With auto-graded projects you can define your own automated tests that are run against your
                    users/students' submissions to validate their score.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          {customModules?.results?.map((m) => (
            <CustomModuleBox key={m.id} customModule={m} />
          ))}
        </div>
      )}
    </Container>
  )
}

export default AutoGradedProjectsTab
