import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Tooltip, Button, Dropdown, Skeleton } from 'antd'
import { MoreVertical24Filled } from '@fluentui/react-icons'
import { getPlatformURL } from '@/helpers/env'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import { capitalize } from '@/utils/functions'
import CustomModuleStatusTag from '@/components/CustomModuleStatusTag'
import { Container } from './styles'

const CustomModuleBox = ({ customModule, isBoxLoading }) => {
  const { currentAccount } = useSelector((state) => state.accounts)

  const lastVersion = customModule?.versions?.[customModule?.versions?.length - 1]

  const getStatusDate = () => {
    if (lastVersion?.status === 'draft') return `Created ${dayjs(lastVersion?.created).from()}`
    if (lastVersion?.status === 'published') return `Published ${dayjs(lastVersion?.created).from()}`
  }

  if (isBoxLoading || !customModule)
    return (
      <Container className="custom-module-box is-loading">
        <div className="main-content">
          <Skeleton className="left-side" active avatar title={false} paragraph={{ rows: 0 }} />
          <Skeleton className="right-side" active title={false} paragraph={{ rows: 2 }} />
        </div>
      </Container>
    )

  return (
    <Container className="custom-module-box">
      <div className="main-content">
        <div className="image-container">{renderModuleImage(customModule)}</div>

        <div className="content">
          <div className="header">
            <div className="title-container">
              {lastVersion ? (
                <Link
                  className="menu-item"
                  to={`${getPlatformURL(currentAccount?.slug)}projects/${customModule?.id}/versions/${lastVersion?.id}`}
                  target="_blank"
                >
                  <h5 className="title">{customModule?.name}</h5>
                </Link>
              ) : (
                <h5 className="title">{customModule?.name}</h5>
              )}

              {lastVersion?.author?.name && (
                <div className="author-info">
                  Version created by <span>{capitalize(lastVersion?.author?.name)}</span>
                </div>
              )}

              <Dropdown
                overlayClassName={'custom-module-box-menu'}
                trigger={['click']}
                menu={{
                  items: [],
                }}
              >
                {false ? (
                  <Button className="open-options-button" type="text" shape="circle" icon={<MoreVertical24Filled />} />
                ) : (
                  <></>
                )}
              </Dropdown>
            </div>

            <div className="info-box">
              {lastVersion ? (
                <div className="version-item">
                  <h5 className="version-code">V{lastVersion?.version_number}</h5>

                  <Tooltip title={getStatusDate}>
                    <div className="status-tag-container">
                      <CustomModuleStatusTag status={lastVersion?.status} />
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <span />
              )}

              <div className="stack-info">
                <img className="image" src={customModule?.stack?.image_url} alt={customModule?.stack?.name} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CustomModuleBox
