import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { Modal as AntModal } from 'antd'

const fadeInAnimation = keyframes`${fadeIn}`

export const Modal = styled(AntModal)`
  height: calc(100% - 50px);
  max-width: 1440px;
  width: 100% !important;
  top: 30px;

  .ant-modal-content {
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray4} !important;
    padding: 32px;
  }

  .ant-modal-body {
    height: 100%;

    .container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .content-card {
        width: 100%;
        background: linear-gradient(
          180deg,
          ${(props) => props.theme.colors.geekblue1} 0%,
          ${(props) => props.theme.colors.gray1} 100%
        );
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        overflow: auto;
        padding: 45px 20px;

        .section-name {
          color: ${(props) => props.theme.colors.gray9};
          font-weight: 700;
          margin: 0px;
        }

        .text {
          color: ${(props) => props.theme.colors.gray8};
          margin: 0px;
        }

        .hero-container {
          display: flex;
          align-items: center;
          gap: 9px;
          padding: 5px 26px;

          .icon-container {
            width: 38px;
            height: 38px;
            background-color: ${(props) => props.theme.colors.geekblue4};
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              color: ${(props) => props.theme.colors.gray1};
            }
          }

          .title {
            margin: 0px;
          }
        }

        > .content {
          padding: 5px 40px;

          > .title-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
            padding-bottom: 20px;
            margin-bottom: 20px;
          }

          .stacks-container {
            display: flex;
            flex-direction: column;
            gap: 18px;
            padding: 20px 0px;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
            padding-bottom: 20px;
            margin-bottom: 20px;

            .stacks-content {
              height: 468px;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              overflow: auto;
              margin-bottom: 20px;

              .stack-content {
                height: 224px;
                width: calc(33.33% - 10px);
                background-color: ${(props) => props.theme.colors.gray1};

                .stack-box {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 14px;
                  border: 1px solid transparent;
                  border-radius: 5px;
                  padding: 0px 30px 0px;
                  transition: 0.3s all;
                  cursor: pointer;

                  .image-container {
                    margin-top: 24px;

                    .image {
                      height: 28px;
                    }
                  }

                  .name {
                    margin: 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }

                  .description,
                  .description > p {
                    color: ${(props) => props.theme.colors.gray7};
                    font-size: 12px;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    margin: 0px;
                  }

                  &:hover {
                    border: 1px solid ${(props) => props.theme.colors.geekblue3};
                  }

                  &.selected {
                    border: 1px solid ${(props) => props.theme.colors.geekblue5};
                  }

                  &.custom {
                    background-color: ${(props) => props.theme.colors.geekblue1};
                    border: 2px dashed var(--PRO-gradient, ${(props) => props.theme.colors.geekblue3});
                    cursor: default;
                    justify-content: center;

                    .description {
                      font-size: 14px;
                    }

                    .link > button {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

          .stack-sizes-container {
            display: flex;
            flex-direction: column;
            gap: 18px;
            padding: 20px 0px;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
            padding-bottom: 20px;
            margin-bottom: 20px;

            .stack-sizes-content {
              width: 100%;
              display: flex;
              gap: 10px;
              margin-bottom: 20px;

              .stack-size-content {
                height: 240px;
                width: calc(25% - 10px);
                background-color: ${(props) => props.theme.colors.gray1};

                .stack-size-box {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 22px;
                  border: 1px solid transparent;
                  border-radius: 5px;
                  padding: 0px 30px 0px;
                  transition: 0.3s all;
                  cursor: pointer;

                  .header {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .image-container {
                      height: 26px;
                      width: 36px;
                      background-color: ${(props) => props.theme.colors.geekblue1};
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 7px;
                      margin-top: 6px;

                      > svg {
                        height: 20px;
                        width: 20px;
                        color: ${(props) => props.theme.colors.geekblue5};
                      }
                    }

                    .name {
                      margin: 0px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    }
                  }

                  .value-boxes-container {
                    display: flex;
                    flex-direction: column;
                    gap: 9px;

                    .value-box {
                      background-color: ${(props) => props.theme.colors.gray3};
                      display: flex;
                      justify-content: space-between;
                      gap: 10px;
                      border-radius: 4px;
                      padding: 4px 6px;

                      .key {
                        color: ${(props) => props.theme.colors.gray7};
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        font-size: 12px;
                        line-height: 20px;
                        margin: 0px;

                        .icon {
                          height: 20px;
                          width: 20px;
                          color: ${(props) => props.theme.colors.gray7};
                        }
                      }

                      .value {
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 20px;
                        margin: 0px;
                      }
                    }
                  }

                  &:hover {
                    border: 1px solid ${(props) => props.theme.colors.geekblue3};
                  }

                  &.selected {
                    border: 1px solid ${(props) => props.theme.colors.geekblue5};
                  }

                  &.custom {
                    background-color: ${(props) => props.theme.colors.geekblue1};
                    border: 2px dashed var(--PRO-gradient, ${(props) => props.theme.colors.geekblue3});
                    cursor: default;

                    .value-box {
                      background: linear-gradient(84deg, #222249 24.3%, #5479f7 67.59%, #89d2c2 99.33%);
                      flex-direction: column;
                      gap: 4px;
                      padding: 12px;

                      .text {
                        color: ${(props) => props.theme.colors.gray1};
                        margin: 0px;
                      }

                      .link {
                        color: ${(props) => props.theme.colors.gray1};
                        font-weight: 700;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }

          .gpus-container {
            display: flex;
            flex-direction: column;
            gap: 18px;
            padding: 20px 0px;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
            padding-bottom: 20px;
            margin-bottom: 20px;

            .section {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 12px;

              .extra-content {
                color: ${(props) => props.theme.colors.gray1};
                background: linear-gradient(84deg, #222249 24.3%, #5479f7 67.59%, #89d2c2 99.33%);
                display: flex;
                align-items: center;
                gap: 6px;
                border-radius: 6px;
                padding: 6px 12px;

                .icon {
                  height: 20px;
                  width: 20px;
                  color: ${(props) => props.theme.colors.gray1};
                }

                .text {
                  color: ${(props) => props.theme.colors.gray1};
                  margin: 0px;
                }

                .link {
                  color: ${(props) => props.theme.colors.gray1};
                  font-weight: 700;
                  text-decoration: underline;
                }
              }
            }

            .gpus-content {
              width: 100%;
              display: flex;
              gap: 60px;
              margin-bottom: 20px;

              .gpu-content {
                display: flex;
                gap: 10px;

                .gpu-box {
                  width: 140px;
                  height: 100%;
                  background-color: ${(props) => props.theme.colors.gray3};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 9px;
                  border: 1px solid transparent;
                  border-radius: 5px;
                  padding: 2px 0px;
                  transition: 0.3s all;
                  cursor: pointer;

                  .image {
                    filter: grayscale(1);
                  }

                  .name {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 20px;
                    margin: 0px;
                  }

                  &:hover {
                    border: 1px solid ${(props) => props.theme.colors.geekblue3};
                  }

                  &.disabled {
                    opacity: 0.5;
                    border: 1px solid transparent;
                    cursor: not-allowed;
                  }

                  &.selected {
                    border: 1px solid ${(props) => props.theme.colors.geekblue5};

                    .image {
                      filter: grayscale(0);
                    }
                  }
                }
              }

              .size-content {
                width: 100%;
              }
            }
          }

          .other-configs-container {
            display: flex;
            flex-direction: column;
            gap: 18px;
            padding: 20px 0px;
            margin-bottom: 20px;

            .configs-content {
              width: 100%;
              display: flex;
              gap: 60px;
              margin-bottom: 20px;

              .config-content {
                width: 100%;
                display: flex;
                gap: 10px;

                .config-box {
                  width: 50%;
                  background-color: ${(props) => props.theme.colors.geekblue1};
                  border-radius: 6px;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  padding: 17px 14px;

                  > .header {
                    display: flex;
                    justify-content: space-between;

                    > .title-container {
                      display: flex;
                      align-items: center;
                      gap: 5px;

                      .icon {
                        height: 20px;
                        width: 20px;
                      }

                      .title {
                        font-size: 12px;
                        line-height: 20px;
                        margin: 0px;
                      }
                    }
                  }

                  .description {
                    color: ${(props) => props.theme.colors.gray7};
                    font-size: 12px;
                    line-height: 20px;
                    margin: 0px;
                  }
                }
              }
            }
          }
        }
      }

      .preview-card {
        width: 410px;
        background-color: ${(props) => props.theme.colors.gray1};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        padding: 28px 30px;

        hr {
          border-top-color: ${(props) => props.theme.colors.gray4};
          margin: 14px 0px;
        }

        > .content {
          height: 100%;
        }

        .header {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .module-image {
            width: 84px;
            opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
          }

          .title-container {
            .title {
              font-weight: 400;
              margin: 0px 0px 6px;

              &.no-content {
                color: ${(props) => props.theme.colors.gray7};
              }
            }

            .description {
              color: ${(props) => props.theme.colors.gray7};
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }

        .section {
          display: flex;
          flex-direction: column;
          gap: 4px;

          &.boxes-section {
            gap: 9px;
          }

          .section-name {
            color: ${(props) => props.theme.colors.gray6};
            font-weight: 700;
            margin: 0px;
          }

          .value {
            display: flex;
            align-items: center;
            gap: 4px;
            margin: 0px;

            .image {
              height: 18px;
            }
          }

          .box {
            background-color: ${(props) => props.theme.colors.geekblue1};
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            border-radius: 6px;
            padding: 9px 14px;

            .title-container {
              display: flex;
              align-items: center;
              gap: 5px;

              .icon {
                height: 18px;
                width: 18px;
              }
            }

            .text {
              font-size: 12px;
              margin: 0px;
            }

            .value {
              width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;

              .check-icon {
                height: 22px;
                width: 22px;
                color: ${(props) => props.theme.colors.cyan5};
              }
            }
          }
        }

        .actions {
          .buttons-container {
            display: flex;
            justify-content: space-between;

            > button {
              min-width: 120px;
            }
          }
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }
`
