import {
  SET_PLAYGROUNDS_LOADING,
  PLAYGROUNDS_DATA_UPDATED,
  SET_PLAYGROUND_PREVIEW_LOADING,
  RESET_PLAYGROUNDS_STATE,
  SET_PLAYGROUNDS_ERROR,
} from '@/store/playgrounds'
import { VERSIONS_ANALYTICS_UPDATED, PLAYGROUNDS_ANALYTICS_UPDATED } from '@/store/accounts'
import {
  getPlaygrounds,
  postPlayground,
  patchPlayground,
  postPlaygroundPreview,
  postPlaygroundComment,
  patchPlaygroundComment,
  deletePlaygroundComment,
} from '@/services/playgrounds'
import { showToast } from '@/utils/toast'

const fetchPlaygrounds = (params) => async (dispatch) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const playgrounds = await getPlaygrounds(params)

    if (params?.['page_size'] === 'None') {
      dispatch(PLAYGROUNDS_DATA_UPDATED({ count: playgrounds?.length || 0, results: playgrounds }))
    } else {
      dispatch(PLAYGROUNDS_DATA_UPDATED(playgrounds))
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const createPlayground = (data, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { playgrounds } = getState()
    const { playgroundsData } = playgrounds

    const playground = await postPlayground(data)

    dispatch(
      PLAYGROUNDS_DATA_UPDATED({
        ...playgroundsData,
        results: [playground, ...playgroundsData.results],
      }),
    )

    if (cb) {
      cb(playground)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const updatePlayground = (playgroundId, data, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { playgrounds } = getState()
    const { playgroundsData } = playgrounds

    const updatedPlayground = await patchPlayground(playgroundId, data)

    dispatch(
      PLAYGROUNDS_DATA_UPDATED({
        ...playgroundsData,
        results: playgroundsData.results.map((p) => (p.id === playgroundId ? updatedPlayground : p)),
      }),
    )

    if (cb) {
      cb(updatedPlayground)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const createPlaygroundPreview = (playgroundId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUND_PREVIEW_LOADING(true))

    const { versionsAnalytics } = getState().accounts

    const playgroundPreviewData = await postPlaygroundPreview(playgroundId, data)

    const auxVersionsAnalytics = {
      ...versionsAnalytics,
      last_preview_html: playgroundPreviewData?.preview_html,
    }
    dispatch(VERSIONS_ANALYTICS_UPDATED(auxVersionsAnalytics))
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUND_PREVIEW_LOADING(false))
  }
}

const createComment = (playgroundId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { playgroundsAnalytics, versionsAnalytics } = getState().accounts

    const response = await postPlaygroundComment(playgroundId, data)
    const newCommentEvent = {
      content_md: data?.content_md,
      event_name: 'content.comment.create',
      id: response?.id,
      datetime: response?.created,
      content_html: response?.content_html,
      user: response?.user,
    }

    const auxVersionsAnalytics = {
      ...versionsAnalytics,
      timeline: [...versionsAnalytics.timeline, newCommentEvent],
    }
    dispatch(VERSIONS_ANALYTICS_UPDATED(auxVersionsAnalytics))

    const auxPlaygroundsAnalytics = {
      ...playgroundsAnalytics,
      results: playgroundsAnalytics.results.map((playgroundsData) => {
        if (playgroundsData.user_id === data.user_id) {
          return {
            ...playgroundsData,
            playgrounds: playgroundsData.playgrounds.map((p) => {
              if (p.playground_copy_id === playgroundId) {
                return {
                  ...p,
                  comments_count: p.comments_count + 1,
                }
              }

              return p
            }),
          }
        }

        return playgroundsData
      }),
    }
    dispatch(PLAYGROUNDS_ANALYTICS_UPDATED(auxPlaygroundsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const updateComment = (playgroundId, commentId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { versionsAnalytics } = getState().accounts

    const response = await patchPlaygroundComment(playgroundId, commentId, data)
    const newCommentEvent = {
      content_md: data?.content_md,
      event_name: 'content.comment.create',
      id: response?.id,
      datetime: response?.created,
      content_html: response?.content_html,
      user: response?.user,
    }

    const auxVersionsAnalytics = {
      ...versionsAnalytics,
      timeline: versionsAnalytics.timeline?.map((e) => (e.id === commentId ? newCommentEvent : e)),
    }

    dispatch(VERSIONS_ANALYTICS_UPDATED(auxVersionsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const deleteComment = (playgroundId, commentId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_PLAYGROUNDS_LOADING(true))

    const { playgroundsAnalytics, versionsAnalytics } = getState().accounts

    await deletePlaygroundComment(playgroundId, commentId)

    const auxVersionsAnalytics = {
      ...versionsAnalytics,
      timeline: versionsAnalytics.timeline?.filter((e) => e.id !== commentId),
    }
    dispatch(VERSIONS_ANALYTICS_UPDATED(auxVersionsAnalytics))

    const auxPlaygroundsAnalytics = {
      ...playgroundsAnalytics,
      results: playgroundsAnalytics.results.map((playgroundsData) => {
        if (playgroundsData.user_id === data.user_id) {
          return {
            ...playgroundsData,
            playgrounds: playgroundsData.playgrounds.map((p) => {
              if (p.playground_copy_id === playgroundId) {
                return {
                  ...p,
                  comments_count: p.comments_count - 1,
                }
              }

              return p
            }),
          }
        }

        return playgroundsData
      }),
    }
    dispatch(PLAYGROUNDS_ANALYTICS_UPDATED(auxPlaygroundsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_PLAYGROUNDS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUNDS_LOADING(false))
  }
}

const resetPlaygroundsState = () => (dispatch) => {
  dispatch(RESET_PLAYGROUNDS_STATE())
}

export {
  fetchPlaygrounds,
  createPlayground,
  updatePlayground,
  createPlaygroundPreview,
  createComment,
  updateComment,
  deleteComment,
  resetPlaygroundsState,
}
