import { configureStore } from '@reduxjs/toolkit'
import { createReduxEnhancer } from '@sentry/react'
import accountsReducer from './accounts'
import activitiesReducer from './activities'
import appReducer from './app'
import issuesReducer from './issues'
import modulesReducer from './modules'
import playgroundsReducer from './playgrounds'
import sessionsReducer from './sessions'
import skillTracksReducer from './skillTracks'
import stacksReducer from './stacks'
import usersReducer from './users'

const reducer = {
  accounts: accountsReducer,
  activities: activitiesReducer,
  app: appReducer,
  issues: issuesReducer,
  modules: modulesReducer,
  playgrounds: playgroundsReducer,
  sessions: sessionsReducer,
  skillTracks: skillTracksReducer,
  stacks: stacksReducer,
  users: usersReducer,
}

const preloadedState = {}

const sentryReduxEnhancer = createReduxEnhancer({
  configureScopeWithState(scope, state) {
    const { users } = state
    const userProfile = users?.userProfile

    if (userProfile) {
      scope.setUser({
        id: userProfile.id,
      })
      // scope.setExtras(extras)
    }
  },
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware()]
  },
  devTools: import.meta.env.NODE_ENV !== 'production',
  preloadedState,
  enhancers: (getDefaultEnhancers) => {
    return [...getDefaultEnhancers(), sentryReduxEnhancer]
  },
})

export default store
