import { Tag as AntdTag } from 'antd'
import styled from 'styled-components'

export const Tag = styled(AntdTag)`
  background-color: ${(props) => props.theme.colors.gray1};
  color: ${(props) => props.theme.colors.gray9};
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  margin: 0px;

  .indicator {
    height: 7px;
    width: 7px;
    background-color: ${(props) => props.theme.colors.gray5};
    border-radius: 50%;
  }

  &.draft .indicator {
    background-color: ${(props) => props.theme.colors.gold5};
  }

  &.published .indicator {
    background-color: ${(props) => props.theme.colors.geekblue5};
  }
`
