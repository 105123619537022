import { useState } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { useInstantSearch, Configure } from 'react-instantsearch'
import { AutoComplete, Input } from 'antd'
import { getAlgoliaSearchClient } from '@/utils/algolia'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import searchParameters from './searchParameters'
import { Container } from './styles'

const Results = ({ label, onSelect }) => {
  const { results, status, indexUiState, setIndexUiState } = useInstantSearch()

  const [autocompleteValue, setAutocompleteValue] = useState()

  const handleQuerySearch = (query) => {
    setAutocompleteValue(query)

    const auxIndexUiState = { configure: { ...indexUiState.configure, query } }
    setIndexUiState(auxIndexUiState)
  }

  const renderOptions = () => {
    const list = results?.hits?.map((hit) => ({
      value: hit['SVG URL'],
      label: (
        <div className="image-container" style={{ width: 80, height: 80, padding: 10 }}>
          {renderModuleImage({ svg_icon_url: hit['SVG URL'] })}
        </div>
      ),
    }))

    return list
  }

  return (
    <div className="results">
      <AutoComplete
        style={{
          width: '100%',
        }}
        size="large"
        value={autocompleteValue}
        onSearch={handleQuerySearch}
        onSelect={(value) => {
          if (onSelect) onSelect(value)
          setAutocompleteValue(autocompleteValue || '')
        }}
        options={renderOptions()}
        virtual={false}
        dropdownRender={(menu) => <div className="dropdown-render-container horizontal">{menu}</div>}
      >
        <Input.Search size="large" placeholder={label || 'Select an image'} allowClear loading={status === 'loading'} />
      </AutoComplete>
    </div>
  )
}

const ModuleImageSearch = ({ label, onSelect }) => {
  const algoliaSearchClient = getAlgoliaSearchClient()

  return (
    <Container>
      <InstantSearch
        searchClient={algoliaSearchClient}
        indexName={import.meta.env.REACT_APP_ALGOLIA_PROJECT_IMAGES_INDEX}
        // FIXME: be ready for next Algolia update
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure {...searchParameters} />

        <Results label={label} onSelect={onSelect} />
      </InstantSearch>
    </Container>
  )
}

export default ModuleImageSearch
